import React, { FC, useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";

import { fetchInstitutionList } from "../../../../store/features/institutionListSlice";
import { showToast, toastType } from "../../../../core/Toast/utils";
import { useAppDispatch } from "../../../../store";
import {
  createInstitution,
  editInstitution,
} from "../../../../services/institutions";
import { FormMode } from "../../../../utils/forms";
import { InstitutionElement } from "../../../../models/institutions";
import { Button } from "../../../../components/Button";
import { Input } from "../../../../components/Input";
import "../../../../styles/form.scss";

type InstitutionFormProps = {
  onClose: () => void;
  mode: FormMode;
  initialValues?: InstitutionElement;
};

export const InstitutionForm: FC<InstitutionFormProps> = ({
  onClose,
  mode,
  initialValues,
}) => {
  const dispatch = useAppDispatch();
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);

  const defaultValues = useMemo(() => {
    return {
      name: initialValues?.name || "",
      street: initialValues?.street || "",
      post_code: initialValues?.post_code || "",
      city: initialValues?.city || "",
    } as FieldValues;
  }, [initialValues]);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onTouched",
    defaultValues,
  });

  const onSubmit = async (data: FieldValues) => {
    setIsFormSubmitting(true);
    (mode === FormMode.CREATE
      ? createInstitution(data)
      : editInstitution(data, initialValues?.id)
    ).then((response) => {
      setIsFormSubmitting(false);
      if (![200, 201].includes(response.status)) {
        if (response.status === 409) {
          showToast(
            "An institution with the given name already exists.",
            toastType.ERROR
          );
        } else {
          showToast("An error occurred.", toastType.ERROR);
        }
      } else {
        showToast(
          mode === FormMode.CREATE ? "Institution created." : "Changes saved.",
          toastType.SUCCESS
        );
        onClose();
        dispatch(fetchInstitutionList({ pageIndex: 0 }));
      }
    });
  };

  return (
    <div className="form">
      <div className="form__header">
        <img src="Create.png" />
        {mode === FormMode.CREATE ? "Create Institution" : "Edit Institution"}
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form__row">
          <Input
            type="text"
            name="name"
            label="Institution name"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Required",
              maxLength: {
                value: 100,
                message: "Max 100 characters",
              },
            }}
          />
        </div>

        <div className="form__row">
          <Input
            type="text"
            name="street"
            label="Street"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Required",
              maxLength: {
                value: 100,
                message: "Max 100 characters",
              },
            }}
          />
        </div>

        <div className="form__row">
          <Input
            type="text"
            name="post_code"
            label="Post code"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Required",
              maxLength: {
                value: 100,
                message: "Max 100 characters",
              },
            }}
          />

          <Input
            type="text"
            name="city"
            label="City"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Required",
              maxLength: {
                value: 100,
                message: "Max 100 characters",
              },
            }}
          />
        </div>

        <Button
          label={mode === FormMode.CREATE ? "Create Institution" : "Save"}
          type="submit"
          className="form__button"
          disabled={isFormSubmitting || !isDirty}
        />
      </form>
    </div>
  );
};
