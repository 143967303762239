import React, { FC, useCallback, useState } from "react";

import { FormMode } from "../../../../utils/forms";
import { useAppDispatch } from "../../../../store";
import { fetchHcpList } from "../../../../store/features/hcpListSlice";
import { HcpElement } from "../../../../models/hcp";
import { showToast, toastType } from "../../../../core/Toast/utils";
import { deleteHealthcareProfessional } from "../../../../services/healthcareProfessionals";
import { DeleteDialogContent } from "../../../../components/DeleteDialogContent";
import { Modal, ModalMode } from "../../../../components/Modal";
import { TableActionButtonWrapper } from "../../../../components/Table/components/TableActionButtonWrapper";
import { HealthcareProfessionalForm } from "../HealthcareProfessionalForm";

type HealthcareProfessionalActionsProps = {
  hcp: HcpElement;
};

export const HealthcareProfessionalActions: FC<
  HealthcareProfessionalActionsProps
> = ({ hcp }) => {
  const dispatch = useAppDispatch();

  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState<boolean>(false);
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);

  const toggleDeleteModal = useCallback(
    () => setDeleteModalOpen((state) => !state),
    []
  );

  const toggleEditModal = useCallback(
    () => setEditModalOpen((state) => !state),
    []
  );

  const onDelete = async () => {
    setIsDeleteInProgress(true);
    deleteHealthcareProfessional(hcp.id).then((response) => {
      setIsDeleteInProgress(false);
      if (response.status !== 200) {
        showToast("An error occurred.", toastType.ERROR);
      } else {
        showToast("User deleted.", toastType.SUCCESS);
        toggleDeleteModal();
        dispatch(fetchHcpList(0));
      }
    });
  };

  return (
    <div>
      <Modal
        open={isDeleteModalOpen}
        onClose={toggleDeleteModal}
        mode={ModalMode.DIALOG}
      >
        <DeleteDialogContent
          message={`Delete healthcare professional ${hcp.first_name} ${hcp.first_name}?`}
          onDelete={onDelete}
          onCancel={toggleDeleteModal}
          deleteDisabled={isDeleteInProgress}
        />
      </Modal>

      <Modal
        open={isEditModalOpen}
        onClose={toggleEditModal}
        mode={ModalMode.FORM}
      >
        <HealthcareProfessionalForm
          onClose={toggleEditModal}
          mode={FormMode.EDIT}
          initialValues={hcp}
        />
      </Modal>

      <TableActionButtonWrapper
        onDelete={toggleDeleteModal}
        onEdit={toggleEditModal}
      />
    </div>
  );
};
