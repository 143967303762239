import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";

import { Button } from "../../components/Button";
import { Modal } from "../../components/Modal";
import { StatusWrapper } from "../../components/StatusWrapper/StatusWrapper";
import { Table } from "../../components/Table";
import { GlobalAdminElement } from "../../models/globalAdmin";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  clearData,
  fetchGlobalAdminList,
} from "../../store/features/globalAdminListSlice";
import { FormMode } from "../../utils/forms";
import { GlobalAdminActions } from "./components/GlobalAdminActions";
import { GlobalAdminForm } from "./components/GlobalAdminForm";
import { columns, GlobalAdminListData } from "./constants";
import "./styles.scss";

const prepareData = (
  fetchedData: GlobalAdminElement[]
): GlobalAdminListData[] => {
  const tableData: GlobalAdminListData[] = [];
  fetchedData?.map((globalAdmin) => {
    tableData.push({
      title: globalAdmin.title || "",
      first_name: globalAdmin.first_name,
      last_name: globalAdmin.last_name,
      email: globalAdmin.email,
      actions: <GlobalAdminActions globalAdmin={globalAdmin} />,
    });
  });

  return tableData;
};

export const GlobalAdmin: FC = () => {
  const dispatch = useAppDispatch();
  const globalAdminList = useAppSelector((state) => state.globalAdminList);
  const [isCreateModalOpen, setCreateModalOpen] = useState<boolean>(false);

  const data = useMemo<GlobalAdminListData[]>(
    () => prepareData(globalAdminList.content),
    [globalAdminList]
  );

  const toggleCreateModal = useCallback(
    () => setCreateModalOpen((state) => !state),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<GlobalAdminListData>({ columns, data });

  useEffect(() => {
    dispatch(fetchGlobalAdminList(0));

    return () => {
      dispatch(clearData());
    };
  }, []);

  const handlePageChange = useCallback((pageNumber: number) => {
    dispatch(fetchGlobalAdminList(pageNumber));
  }, []);

  return (
    <div className="global-admin-list">
      <Modal open={isCreateModalOpen} onClose={toggleCreateModal}>
        <GlobalAdminForm onClose={toggleCreateModal} mode={FormMode.CREATE} />
      </Modal>

      <StatusWrapper status={globalAdminList.status}>
        <Table<GlobalAdminListData>
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
          pageCount={globalAdminList.pageCount}
          pageNumber={globalAdminList.pageNumber}
          goToPage={handlePageChange}
        />
      </StatusWrapper>

      <Button label="Create" onClick={toggleCreateModal} />
    </div>
  );
};
