import React, { FC } from "react";
import {
  Routes,
  Route,
  NavLink,
  Navigate,
  BrowserRouter,
  Outlet,
} from "react-router-dom";
import cn from "classnames";

import { GlobalAdmin } from "../../pages/GlobalAdmins";
import { InstitutionAdmins } from "../../pages/InstitutionAdmins";
import { Institutions } from "../../pages/Institutions";
import { Patients } from "../../pages/Patients";
import { HealthcareProfessionals } from "../../pages/HealthcareProfessionals";
import {
  appRoutes,
  checkUserAccess,
  countUserAvailableRoutes,
  redirectUser,
  userGroups,
} from "./utils";
import { useAppSelector } from "../../store";
import { ProtectedRoute } from "./";
import "./styles.scss";

export const Router: FC = () => {
  const loggedUser = useAppSelector((state) => state.user);
  const userGroup = loggedUser.role as userGroups;
  const routesCount = countUserAvailableRoutes(userGroup);

  return ( <div className={cn(["router", { "router--with-nav": routesCount > 1 }])}>
      <BrowserRouter>
        {routesCount > 1 && (
          <nav className="router__navigation">
            <NavLink
              to={`/${appRoutes.GLOBAL_ADMINS}`}
              className={({ isActive }) =>
                cn(["router__navlink", { "router__navlink--active": isActive }])
              }
            >
              <div>Global Admins</div>
            </NavLink>
            <NavLink
              to={`/${appRoutes.INSTITUTION_ADMINS}`}
              className={({ isActive }) =>
                cn(["router__navlink", { "router__navlink--active": isActive }])
              }
            >
              <div>Institution Admins</div>
            </NavLink>
            <NavLink
              to={`/${appRoutes.INSTITUTIONS_LIST}`}
              className={({ isActive }) =>
                cn(["router__navlink", { "router__navlink--active": isActive }])
              }
            >
              <div>Institutions</div>
            </NavLink>
          </nav>
        )}
        <Outlet />

        <div className="router__content">
          <Routes>
            <Route
              path={appRoutes.GLOBAL_ADMINS}
              element={
                <ProtectedRoute
                  isAllowed={checkUserAccess(userGroup, appRoutes.GLOBAL_ADMINS)}
                >
                  <GlobalAdmin />
                </ProtectedRoute>
              }
            />
            <Route
              path={appRoutes.INSTITUTION_ADMINS}
              element={
                <ProtectedRoute
                  isAllowed={checkUserAccess(
                    userGroup,
                    appRoutes.INSTITUTION_ADMINS
                  )}
                >
                  <InstitutionAdmins />
                </ProtectedRoute>
              }
            />
            <Route
              path={appRoutes.INSTITUTIONS_LIST}
              element={
                <ProtectedRoute
                  isAllowed={checkUserAccess(userGroup, appRoutes.INSTITUTIONS_LIST)}
                >
                  <Institutions />
                </ProtectedRoute>
              }
            />
            <Route
              path={appRoutes.HEALTHCARE_PROFESSIONALS}
              element={
                <ProtectedRoute
                  isAllowed={checkUserAccess(userGroup, appRoutes.HEALTHCARE_PROFESSIONALS)}
                >
                  <HealthcareProfessionals />
                </ProtectedRoute>
              }
            />
            <Route
              path={appRoutes.PATIENTS}
              element={
                <ProtectedRoute
                  isAllowed={checkUserAccess(userGroup, appRoutes.PATIENTS)}
                >
                  <Patients />
                </ProtectedRoute>
              }
            />
            <Route
              path="*"
              element={<Navigate to={`/${redirectUser(userGroup)}`} replace />}
            />
          </Routes>
        </div>
      </BrowserRouter>
    </div>)
  ;
};
