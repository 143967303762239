import React, { FC, useCallback, useState } from "react";

import { FormMode } from "../../../../utils/forms";
import { showToast, toastType } from "../../../../core/Toast/utils";
import { useAppDispatch } from "../../../../store";
import { fetchGlobalAdminList } from "../../../../store/features/globalAdminListSlice";
import { GlobalAdminElement } from "../../../../models/globalAdmin";
import { deleteGlobalAdmin } from "../../../../services/globalAdmins";
import { DeleteDialogContent } from "../../../../components/DeleteDialogContent";
import { Modal, ModalMode } from "../../../../components/Modal";
import { TableActionButtonWrapper } from "../../../../components/Table/components/TableActionButtonWrapper";
import { GlobalAdminForm } from "../GlobalAdminForm";

type GlobalAdminActionsProps = {
  globalAdmin: GlobalAdminElement;
};

export const GlobalAdminActions: FC<GlobalAdminActionsProps> = ({
  globalAdmin,
}) => {
  const dispatch = useAppDispatch();

  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState<boolean>(false);
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);

  const toggleDeleteModal = useCallback(
    () => setDeleteModalOpen((state) => !state),
    []
  );

  const toggleEditModal = useCallback(
    () => setEditModalOpen((state) => !state),
    []
  );

  const onDelete = async () => {
    setIsDeleteInProgress(true);
    deleteGlobalAdmin(globalAdmin.id).then((response) => {
      setIsDeleteInProgress(false);
      if (response.status !== 200) {
        showToast("An error occurred.", toastType.ERROR);
      } else {
        showToast("User deleted.", toastType.SUCCESS);
        toggleDeleteModal();
        dispatch(fetchGlobalAdminList(0));
      }
    });
  };

  return (
    <div>
      <Modal
        open={isDeleteModalOpen}
        onClose={toggleDeleteModal}
        mode={ModalMode.DIALOG}
      >
        <DeleteDialogContent
          message={`Delete global admin ${globalAdmin.first_name} ${globalAdmin.last_name}?`}
          onDelete={onDelete}
          onCancel={toggleDeleteModal}
          deleteDisabled={isDeleteInProgress}
        />
      </Modal>

      <Modal
        open={isEditModalOpen}
        onClose={toggleEditModal}
        mode={ModalMode.FORM}
      >
        <GlobalAdminForm
          onClose={toggleEditModal}
          mode={FormMode.EDIT}
          initialValues={globalAdmin}
        />
      </Modal>

      <TableActionButtonWrapper
        onDelete={toggleDeleteModal}
        onEdit={toggleEditModal}
      />
    </div>
  );
};
