import React, { FC } from "react";
import { Navigate } from "react-router-dom";

import { useAppSelector } from "../../store";
import { redirectUser, userGroups } from "./utils";

type ProtectedRouteProps = {
  children: JSX.Element;
  isAllowed: boolean;
};

export const ProtectedRoute: FC<ProtectedRouteProps> = ({
  children,
  isAllowed,
}) => {
  const loggedUser = useAppSelector((state) => state.user);
  
  return isAllowed ? (
    children
  ) : (
    <Navigate to={`/${redirectUser(loggedUser.role as userGroups)}`} replace />
  );
};
