import React, { FC } from "react";

import { Loader } from "../Loader";
import { LoadingStatus } from "./constants";

type StatusWrapperProps = {
  children: JSX.Element;
  status: LoadingStatus;
};

export const StatusWrapper: FC<StatusWrapperProps> = ({ children, status }) => {
  return status === LoadingStatus.SUCCESS ? children : <Loader />;
};
