import { toast, ToastOptions } from "react-toastify";

export enum toastType {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  WARN = "WARN",
}

const toastOptions: ToastOptions = {
  position: "bottom-center",
  autoClose: 4000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: "colored",
};

export const showToast = (message: string, type: toastType) => {
  switch (type) {
    case toastType.SUCCESS:
      return toast.success(message, {...toastOptions, className: 'toast--success'});
    case toastType.ERROR:
      return toast.error(message, {...toastOptions, className: 'toast--error'});
    case toastType.WARN:
      return toast.warn(message, {...toastOptions, className: 'toast--warn'});
  }
};
