import React, { FC } from "react";

import "./styles.scss";

export const Loader: FC = () => {
  return (
    <div className="loader__wrapper">
      <div className="loader" />
    </div>
  );
};
