import { FieldValues } from "react-hook-form";

import { getUserToken, GLOBAL_ADMINS_URL } from "./utils";

export const createGlobalAdmin = async (
  data: FieldValues
): Promise<Response> => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getUserToken()}`,
    },
    body: JSON.stringify(data),
  };
  return fetch(GLOBAL_ADMINS_URL, requestOptions);
};

export const editGlobalAdmin = async (
  data: FieldValues,
  id: string | undefined
): Promise<Response> => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getUserToken()}`,
    },
    body: JSON.stringify(data),
  };
  return fetch(`${GLOBAL_ADMINS_URL}/${id}`, requestOptions);
};

export const deleteGlobalAdmin = async (id: string): Promise<Response> => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${await getUserToken()}`,
    },
  };
  return fetch(`${GLOBAL_ADMINS_URL}/${id}`, requestOptions);
};

export const getGlobalAdminList = async (pageIndex: number) => {
  return await fetch(`${GLOBAL_ADMINS_URL}?page=${pageIndex}&size=10`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${await getUserToken()}`,
    },
  });
};
