import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { LoadingStatus } from "../../components/StatusWrapper/constants";
import { InstitutionElement } from "../../models/institutions";
import { getInstitutionList } from "../../services/institutions";

export type InstitutionListState = {
  content: InstitutionElement[];
  status: LoadingStatus;
  pageNumber: number;
  pageCount: number;
};

const initialState: InstitutionListState = {
  content: [],
  status: LoadingStatus.IDLE,
  pageNumber: 0,
  pageCount: 0,
};

export const fetchInstitutionList = createAsyncThunk(
  "institutionList/fetchInstitutionList",
  async (params: { pageIndex: number; pageSize?: number }) => {
    const response = await getInstitutionList(params);
    const data = response.json();
    return data;
  }
);

export const InstitutionListSlice = createSlice({
  name: "institutionList",
  initialState: initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInstitutionList.pending, (state) => {
      return { ...state, status: LoadingStatus.LOADING };
    });
    builder.addCase(fetchInstitutionList.fulfilled, (state, action) => {
      return {
        ...state,
        content: action.payload.content,
        status: LoadingStatus.SUCCESS,
        pageCount: action.payload.total_pages,
        pageNumber: action.payload.number,
      };
    });
  },
});

export default InstitutionListSlice.reducer;
export const { clearData } = InstitutionListSlice.actions;
