import { Column } from "react-table";

export type InstitutionListData = {
  name: string;
  street: string;
  postCode: string;
  city: string;
  actions: React.ReactNode;
};

export const columns: Column<InstitutionListData>[] = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Street",
    accessor: "street",
  },
  {
    Header: "Post code",
    accessor: "postCode",
  },
  {
    Header: "City",
    accessor: "city",
  },
  {
    accessor: "actions",
  },
];
