import { SelectOption } from "../components/Select";

export const TITLE_OPTIONS: SelectOption[] = [
  { label: "---", value: "" },
  { label: "Mr.", value: "Mr." },
  { label: "Mrs.", value: "Mrs." },
  { label: "Ms.", value: "Ms." },
];

export const EMAIL_VALIDATOR = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export enum FormMode {
  EDIT = "edit",
  CREATE = "create",
}