import React, { FC, useCallback, useEffect, useState } from "react";
import { useTable } from "react-table";

import { HcpElement } from "../../models/hcp";
import { useAppDispatch, useAppSelector } from "../../store";
import { clearData, fetchHcpList } from "../../store/features/hcpListSlice";
import { FormMode } from "../../utils/forms";
import { StatusWrapper } from "../../components/StatusWrapper/StatusWrapper";
import { HealthcareProfessionalActions } from "./components/HealthcareProfessionalActions";
import { Table } from "../../components/Table";
import { Button } from "../../components/Button";
import { Modal } from "../../components/Modal";
import { HealthcareProfessionalForm } from "./components/HealthcareProfessionalForm";
import { columns, HcpListData } from "./constants";
import "./styles.scss";

const prepareData = (fetchedData: HcpElement[]): HcpListData[] => {
  const tableData: HcpListData[] = [];
  fetchedData?.map((hcp) => {
    tableData.push({
      title: hcp.title || "",
      first_name: hcp.first_name,
      last_name: hcp.last_name,
      email: hcp.email,
      actions: <HealthcareProfessionalActions hcp={hcp} />,
    });
  });

  return tableData;
};

export const HealthcareProfessionals: FC = () => {
  const dispatch = useAppDispatch();
  const [isCreateModalOpen, setCreateModalOpen] = useState<boolean>(false);

  const hcpList = useAppSelector((state) => state.hcpList);

  const toggleCreateModal = useCallback(
    () => setCreateModalOpen((state) => !state),
    []
  );

  const data = React.useMemo<HcpListData[]>(
    () => prepareData(hcpList.content),
    [hcpList]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<HcpListData>({ columns, data });

  useEffect(() => {
    dispatch(fetchHcpList(0));

    return () => {
      dispatch(clearData());
    };
  }, []);

  const handlePageChange = useCallback((pageNumber: number) => {
    dispatch(fetchHcpList(pageNumber));
  }, []);

  return (
    <div className="hcp-list">
      <Modal open={isCreateModalOpen} onClose={toggleCreateModal}>
        <HealthcareProfessionalForm onClose={toggleCreateModal} mode={FormMode.CREATE}/>
      </Modal>

      <div>
        <div className="hcp-list__title">Healthcare Professionals list</div>
        <StatusWrapper status={hcpList.status}>
          <Table<HcpListData>
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            rows={rows}
            prepareRow={prepareRow}
            pageCount={hcpList.pageCount}
            pageNumber={hcpList.pageNumber}
            goToPage={handlePageChange}
          />
        </StatusWrapper>
      </div>
      <Button label="Create" onClick={toggleCreateModal} />
    </div>
  );
};
