import React, { FC, InputHTMLAttributes } from "react";
import { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";
import cn from "classnames";

import { ValidationSchema } from "../../models/form";
import "./styles.scss";

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  errors?: FieldErrors<FieldValues>;
  register: UseFormRegister<FieldValues>;
  name: string;
  validationSchema?: ValidationSchema;
};

export const Input: FC<InputProps> = ({
  label,
  errors,
  name,
  validationSchema,
  register,
  type,
  className,
  disabled
}) => {
  return (
    <div className={cn(["input__wrapper", className])}>
      <label className="input__label">{label}</label>
      <input
        id={name}
        type={type}
        {...register(name, validationSchema)}
        className="input"
        disabled={disabled}
      />
      
      <div className="input__error-wrapper">
        {errors && errors[name] && (
          <div className="input__error">
            {errors[name]?.message?.toString()}
          </div>
        )}
      </div>
    </div>
  );
};
