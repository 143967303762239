export enum userGroups {
  GLOBAL_ADMIN = "GLOBAL_ADMIN",
  HCP = "HCP",
  INSTITUTION_ADMIN = "INSTITUTION_ADMIN",
}

export enum appRoutes {
  GLOBAL_ADMINS = "global-admins",
  INSTITUTION_ADMINS = "institution-admins",
  INSTITUTIONS_LIST = "institutions",
  HEALTHCARE_PROFESSIONALS = "healthcare-professionals",
  PATIENTS = "patients",
}

type routeAllowedRoles = {
  route: appRoutes;
  roles: userGroups[];
};

const routeAllowedRoles: routeAllowedRoles[] = [
  {
    route: appRoutes.GLOBAL_ADMINS,
    roles: [userGroups.GLOBAL_ADMIN],
  },
  {
    route: appRoutes.INSTITUTION_ADMINS,
    roles: [userGroups.GLOBAL_ADMIN],
  },
  {
    route: appRoutes.INSTITUTIONS_LIST,
    roles: [userGroups.GLOBAL_ADMIN],
  },
  {
    route: appRoutes.HEALTHCARE_PROFESSIONALS,
    roles: [userGroups.INSTITUTION_ADMIN],
  },
  {
    route: appRoutes.PATIENTS,
    roles: [userGroups.HCP],
  },
];

export const countUserAvailableRoutes = (userRole: userGroups) => {
  let routesCount = 0;
  routeAllowedRoles.map((route) => {
    if (route.roles.includes(userRole)) {
      routesCount++;
    }
  });
  return routesCount;
};

export const redirectUser = (userRole: userGroups) => {
  switch (userRole) {
    case userGroups.GLOBAL_ADMIN:
      return appRoutes.GLOBAL_ADMINS;
    case userGroups.INSTITUTION_ADMIN:
      return appRoutes.HEALTHCARE_PROFESSIONALS;
    case userGroups.HCP:
      return appRoutes.PATIENTS;
  }
};

export const checkUserAccess = (userRole: userGroups, route: appRoutes) =>
  routeAllowedRoles
    .find((access) => access.route === route)
    ?.roles.includes(userRole) || false;
