import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";

import { InstitutionAdminElement } from "../../models/institutionAdmin";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  clearData,
  fetchInstitutionAdminList,
} from "../../store/features/institutionAdminListSlice";
import { FormMode } from "../../utils/forms";
import { StatusWrapper } from "../../components/StatusWrapper/StatusWrapper";
import { Table } from "../../components/Table";
import { Button } from "../../components/Button";
import { Modal } from "../../components/Modal";
import { InstitutionAdminForm } from "./components/InstitutionAdminForm";
import { InstitutionAdminActions } from "./components/InstitutionAdminActions";
import { columns, InstitutionAdminListData } from "./constants";
import "./styles.scss";

const prepareData = (
  fetchedData: InstitutionAdminElement[]
): InstitutionAdminListData[] => {
  const tableData: InstitutionAdminListData[] = [];
  fetchedData?.map((institutionAdmin) => {
    tableData.push({
      title: institutionAdmin.title || "",
      first_name: institutionAdmin.first_name,
      last_name: institutionAdmin.last_name,
      email: institutionAdmin.email,
      institution: institutionAdmin.institution.name,
      actions: <InstitutionAdminActions institutionAdmin={institutionAdmin} />,
    });
  });

  return tableData;
};

export const InstitutionAdmins: FC = () => {
  const dispatch = useAppDispatch();
  const [isCreateModalOpen, setCreateModalOpen] = useState<boolean>(false);

  const institutionAdminList = useAppSelector(
    (state) => state.institutionAdminList
  );

  const toggleCreateModal = useCallback(
    () => setCreateModalOpen((state) => !state),
    []
  );

  const data = useMemo<InstitutionAdminListData[]>(
    () => prepareData(institutionAdminList.content),
    [institutionAdminList]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<InstitutionAdminListData>({ columns, data });

  useEffect(() => {
    dispatch(fetchInstitutionAdminList(0));

    return () => {
      dispatch(clearData());
    };
  }, []);

  const handlePageChange = useCallback((pageNumber: number) => {
    dispatch(fetchInstitutionAdminList(pageNumber));
  }, []);

  return (
    <div className="institution-admin-list">
      <Modal open={isCreateModalOpen} onClose={toggleCreateModal}>
        <InstitutionAdminForm
          onClose={toggleCreateModal}
          mode={FormMode.CREATE}
        />
      </Modal>

      <StatusWrapper status={institutionAdminList.status}>
        <Table<InstitutionAdminListData>
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
          pageCount={institutionAdminList.pageCount}
          pageNumber={institutionAdminList.pageNumber}
          goToPage={handlePageChange}
        />
      </StatusWrapper>

      <Button label="Create" onClick={toggleCreateModal} />
    </div>
  );
};
