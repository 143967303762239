import React, { FC, useCallback, useState } from "react";

import { FormMode } from "../../../../utils/forms";
import { InstitutionAdminElement } from "../../../../models/institutionAdmin";
import { deleteInstitutionAdmin } from "../../../../services/institutionAdmins";
import { showToast, toastType } from "../../../../core/Toast/utils";
import { useAppDispatch } from "../../../../store";
import { fetchInstitutionAdminList } from "../../../../store/features/institutionAdminListSlice";
import { DeleteDialogContent } from "../../../../components/DeleteDialogContent";
import { Modal, ModalMode } from "../../../../components/Modal";
import { TableActionButtonWrapper } from "../../../../components/Table/components/TableActionButtonWrapper";
import { InstitutionAdminForm } from "../InstitutionAdminForm";

type InstitutionAdminActionsProps = {
  institutionAdmin: InstitutionAdminElement;
};

export const InstitutionAdminActions: FC<InstitutionAdminActionsProps> = ({
  institutionAdmin,
}) => {
  const dispatch = useAppDispatch();

  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState<boolean>(false);
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);

  const toggleDeleteModal = useCallback(
    () => setDeleteModalOpen((state) => !state),
    []
  );

  const toggleEditModal = useCallback(
    () => setEditModalOpen((state) => !state),
    []
  );

  const onDelete = async () => {
    setIsDeleteInProgress(true);
    deleteInstitutionAdmin(institutionAdmin.id).then((response) => {
      setIsDeleteInProgress(false);
      if (response.status !== 200) {
        showToast("An error occurred.", toastType.ERROR);
      } else {
        showToast("User deleted.", toastType.SUCCESS);
        toggleDeleteModal();
        dispatch(fetchInstitutionAdminList(0));
      }
    });
  };

  return (
    <div>
      <Modal
        open={isDeleteModalOpen}
        onClose={toggleDeleteModal}
        mode={ModalMode.DIALOG}
      >
        <DeleteDialogContent
          message={`Delete institution admin ${institutionAdmin.first_name} ${institutionAdmin.last_name}?`}
          onDelete={onDelete}
          onCancel={toggleDeleteModal}
          deleteDisabled={isDeleteInProgress}
        />
      </Modal>

      <Modal
        open={isEditModalOpen}
        onClose={toggleEditModal}
        mode={ModalMode.FORM}
      >
        <InstitutionAdminForm
          onClose={toggleEditModal}
          mode={FormMode.EDIT}
          initialValues={institutionAdmin}
        />
      </Modal>

      <TableActionButtonWrapper
        onDelete={toggleDeleteModal}
        onEdit={toggleEditModal}
      />
    </div>
  );
};
