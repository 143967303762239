import React, { FC, useCallback, useEffect } from "react";
import { AmplifyUser, AuthEventData } from "@aws-amplify/ui";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import jwt_decode from "jwt-decode";

import { StatusWrapper } from "../../components/StatusWrapper/StatusWrapper";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  clearUserData,
  fetchLoggedUserData,
  setUserData,
} from "../../store/features/userSlice";
import { Router } from "../Router";
import { ToastContainer } from "../Toast";
import "./styles.scss";

type PageLayoutProps = {
  user: AmplifyUser | undefined;
  signOut: ((data?: AuthEventData | undefined) => void) | undefined;
};

interface SessionInfo {
  "cognito:groups": string[];
}

export const PageLayout: FC<PageLayoutProps> = ({ user, signOut }) => {
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector((state) => state.user);

  useEffect(() => {
    if (user != null) {
      user.getSession(
        (error: Error | null, session: CognitoUserSession | null) => {
          if (error || session === null) {
            return;
          }

          const sessionIdInfo = jwt_decode<SessionInfo>(
            session.getIdToken().getJwtToken()
          );

          dispatch(
            setUserData({
              role: sessionIdInfo["cognito:groups"][0],
            })
          );
        }
      );
    }

    dispatch(fetchLoggedUserData());
  }, [user]);

  const handleSignOut = useCallback(() => {
    if (signOut !== undefined) {
      signOut();
    }
    dispatch(clearUserData());
  }, [signOut]);

  return (
    <div className="page-layout">
      <div className="page-layout__top">
        <div className="page-layout__logo-wrapper">
          <img src="LogoRed.png" className="page-layout__logo" />
          <div className="page-layout__app-name">Administrator panel</div>
        </div>

        <div className="page-layout__profile-section">
          {loggedUser.institution_name && (
            <div className="page-layout__institution-info-wrapper">
              <div className="page-layout__institution-info">
                <StatusWrapper status={loggedUser.status}>
                  <>
                    <div className="page-layout__institution-label">
                      Institution name:
                    </div>
                    <div className="page-layout__institution-name">
                      {loggedUser.institution_name}
                    </div>
                  </>
                </StatusWrapper>
              </div>
              <img
                src="Institution.png"
                className="page-layout__institution-img"
              />
            </div>
          )}

          <div className="page-layout__profile-info-wrapper">
            <div className="page-layout__profile-info">
              <StatusWrapper status={loggedUser.status}>
                <>
                  <div className="page-layout__user-name">
                    {loggedUser.first_name} {loggedUser.last_name}
                  </div>
                  <div className="page-layout__user-role">
                    {loggedUser.role_display}
                  </div>
                </>
              </StatusWrapper>
            </div>
            <img src="Profile.png" className="page-layout__profile-img" />
          </div>
          <button
            onClick={handleSignOut}
            className="page-layout__sign-out-button"
          >
            <img src="SignOut.png" className="page-layout__sign-out-img" />
          </button>
        </div>
      </div>

      {loggedUser.role !== "" && <Router />}
      <ToastContainer />
    </div>
  );
};
