import React, { FC } from "react";

import { Button, ButtonMode } from "../Button";
import "./styles.scss";

type DeleteDialogContentProps = {
  message: string;
  onDelete: () => void;
  onCancel: () => void;
  deleteDisabled?: boolean;
};

export const DeleteDialogContent: FC<DeleteDialogContentProps> = ({
  message,
  onDelete,
  onCancel,
  deleteDisabled,
}) => {
  return (
    <div className="delete-dialog-content">
      <div className="delete-dialog-content__message">{message}</div>
      <div className="delete-dialog-content__buttons">
        <Button label="Delete" onClick={onDelete} disabled={deleteDisabled} mode={ButtonMode.DELETE}/>
        <Button label="Cancel" onClick={onCancel} mode={ButtonMode.CONFIRM}/>
      </div>
    </div>
  );
};
