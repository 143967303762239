import React, { ButtonHTMLAttributes, FC } from "react";
import cn from "classnames";

import "./styles.scss";

export enum TableActionButtonTheme {
  EDIT = "Edit",
  DELETE = "Delete",
}

export type TableActionButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  theme: TableActionButtonTheme;
};

export const TableActionButton: FC<TableActionButtonProps> = ({
  theme,
  ...props
}) => {
  return (
    <button
      {...props}
      className={cn([
        "table-action-button",
        { "table-action-button--edit": theme === TableActionButtonTheme.EDIT },
        {
          "table-action-button--delete":
            theme === TableActionButtonTheme.DELETE,
        },
      ])}
    >
      <img
        src={theme === TableActionButtonTheme.EDIT ? "Edit.png" : "Delete.png"}
      />
    </button>
  );
};
