import React, { FC } from "react";

import {
  TableActionButton,
  TableActionButtonTheme,
} from "../TableActionButton/TableActionButton";
import "./styles.scss";

export type TableActionButtonWrapperProps = {
  onEdit?: () => void;
  onDelete?: () => void;
  deleteDisabled?: boolean;
  editDisabled?: boolean;
};

export const TableActionButtonWrapper: FC<TableActionButtonWrapperProps> = ({
  onEdit,
  onDelete,
  deleteDisabled,
  editDisabled,
}) => {
  return (
    <div className="table-action-button-wrapper">
      <TableActionButton
        theme={TableActionButtonTheme.EDIT}
        onClick={onEdit}
        disabled={editDisabled}
      />
      <TableActionButton
        theme={TableActionButtonTheme.DELETE}
        onClick={onDelete}
        disabled={deleteDisabled}
      />
    </div>
  );
};
