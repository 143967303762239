import React, { FC, SelectHTMLAttributes } from "react";
import { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";
import cn from "classnames";

import { ValidationSchema } from "../../models/form";
import "./styles.scss";

export type SelectOption = { value: string | number; label: string };

export type InputProps = SelectHTMLAttributes<HTMLSelectElement> & {
  label: string;
  errors?: FieldErrors<FieldValues>;
  register: UseFormRegister<FieldValues>;
  name: string;
  validationSchema?: ValidationSchema;
  options: SelectOption[];
};

export const Select: FC<InputProps> = ({
  label,
  errors,
  name,
  validationSchema,
  register,
  options,
  className,
}) => {
  return (
    <div className={cn(["select__wrapper", className])}>
      <label className="select__label">{label}</label>
      <select
        id={name}
        {...register(name, validationSchema)}
        className="select"
      >
        {options.map((option) => {
          return (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>

      <div className="select__error-wrapper">
        {errors && errors[name] && (
          <div className="select__error">
            {errors[name]?.message?.toString()}
          </div>
        )}
      </div>
    </div>
  );
};
