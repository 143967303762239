import React, { useEffect } from "react";
import { Amplify } from "aws-amplify";
import WebFont from "webfontloader";

import { COGNITO_CONFIG } from "./auth/config";
import { Authenticator } from "./core/Authenticator";
import { BuildVersion } from "./core/BuildVersion";

Amplify.configure(COGNITO_CONFIG);

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Lato"],
      },
    });
  }, []);

  return (
    <>
      <Authenticator />
      <BuildVersion />
    </>
  );
}

export default App;
