import { Column } from "react-table";

export type GlobalAdminListData = {
  title: string;
  first_name: string;
  last_name: string;
  email: string;
  actions: React.ReactNode;
};

export const columns: Column<GlobalAdminListData>[] = [
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "First Name",
    accessor: "first_name",
  },
  {
    Header: "Last Name",
    accessor: "last_name",
  },
  {
    Header: "E-mail address",
    accessor: "email",
  },
  {
    accessor: "actions",
  },
];
