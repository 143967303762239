import React, { FC, useEffect, useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";

import {
  createInstitutionAdmin,
  editInstitutionAdmin,
} from "../../../../services/institutionAdmins";
import { showToast, toastType } from "../../../../core/Toast/utils";
import { InstitutionAdminElement } from "../../../../models/institutionAdmin";
import { fetchInstitutionAdminList } from "../../../../store/features/institutionAdminListSlice";
import {
  clearData,
  fetchInstitutionList,
} from "../../../../store/features/institutionListSlice";
import {
  EMAIL_VALIDATOR,
  FormMode,
  TITLE_OPTIONS,
} from "../../../../utils/forms";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { Button } from "../../../../components/Button";
import { Input } from "../../../../components/Input";
import { StatusWrapper } from "../../../../components/StatusWrapper/StatusWrapper";
import { Select, SelectOption } from "../../../../components/Select";
import "../../../../styles/form.scss";

type InstitutionAdminFormProps = {
  onClose: () => void;
  mode: FormMode;
  initialValues?: InstitutionAdminElement;
};

export const InstitutionAdminForm: FC<InstitutionAdminFormProps> = ({
  onClose,
  initialValues,
  mode,
}) => {
  const dispatch = useAppDispatch();
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const institutionList = useAppSelector((state) => state.institutionList);

  useEffect(() => {
    dispatch(fetchInstitutionList({ pageIndex: 0, pageSize: 1000 }));

    return () => {
      dispatch(clearData());
    };
  }, []);

  const selectInstitutionOptions: SelectOption[] = useMemo(() => {
    const institutions: SelectOption[] = [];
    institutionList.content.map((institution) => {
      institutions.push({ label: institution.name, value: institution.id });
    });

    return institutions;
  }, [institutionList.content]);

  const defaultValues = useMemo(() => {
    return {
      institution_id: initialValues?.institution.id || "",
      title: initialValues?.title || "",
      first_name: initialValues?.first_name || "",
      last_name: initialValues?.last_name || "",
      email: initialValues?.email || "",
      email_confirm: "",
    } as FieldValues;
  }, [initialValues, selectInstitutionOptions]);

  const {
    register,
    handleSubmit,
    getValues,
    trigger,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onTouched",
    defaultValues: defaultValues,
  });

  const onSubmit = async (data: FieldValues) => {
    setIsFormSubmitting(true);
    const formData = {
      institution_id: data.institution_id,
      title: data.title,
      first_name: data.first_name,
      last_name: data.last_name,
      email: mode === FormMode.CREATE ? data.email : undefined,
    };

    (mode === FormMode.CREATE
      ? createInstitutionAdmin(formData)
      : editInstitutionAdmin(formData, initialValues?.id)
    ).then((response) => {
      setIsFormSubmitting(false);
      if (![200, 201].includes(response.status)) {
        if (response.status === 409) {
          showToast(
            "An account with the given email already exists.",
            toastType.ERROR
          );
        } else {
          showToast("An error occurred.", toastType.ERROR);
        }
      } else {
        showToast(
          mode === FormMode.CREATE ? "Account created." : "Changes saved.",
          toastType.SUCCESS
        );
        onClose();
        dispatch(fetchInstitutionAdminList(0));
      }
    });
  };

  const email = watch("email");
  const email_confirm = watch("email_confirm");

  useEffect(() => {
    if (email_confirm) {
      trigger("email_confirm");
    }
  }, [email, email_confirm, trigger]);

  return (
    <div className="form">
      <div className="form__header">
        <img src="Create.png" />
        {mode === FormMode.CREATE
          ? "Create Institution Administrator Account"
          : "Edit Institution Administrator Account"}
      </div>

      <StatusWrapper status={institutionList.status}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form__row">
            <Select
              options={selectInstitutionOptions}
              name="institution_id"
              label="Institutions"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Required",
              }}
            />
          </div>

          <div className="form__row">
            <Select
              options={TITLE_OPTIONS}
              name="title"
              label="Title"
              errors={errors}
              register={register}
              className="form__title-select"
            />

            <Input
              type="text"
              name="first_name"
              label="First Name"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Required",
                maxLength: {
                  value: 100,
                  message: "Max 100 characters",
                },
              }}
            />

            <Input
              type="text"
              name="last_name"
              label="Last Name"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Required",
                maxLength: {
                  value: 100,
                  message: "Max 100 characters",
                },
              }}
            />
          </div>

          <div className="form__row">
            <Input
              type="text"
              name="email"
              label="E-mail address"
              errors={errors}
              register={register}
              disabled={mode === FormMode.EDIT}
              validationSchema={{
                required: "Required",
                maxLength: {
                  value: 100,
                  message: "Max 100 characters",
                },
                pattern: {
                  value: EMAIL_VALIDATOR,
                  message: "Invalid email address",
                },
              }}
            />
          </div>

          {mode === FormMode.CREATE && (
            <div className="form__row">
              <Input
                type="text"
                name="email_confirm"
                label="Confirm e-mail address"
                errors={errors}
                register={register}
                validationSchema={{
                  validate: (value: string) =>
                    value === getValues("email") || "Values are not the same",
                  required: "Required",
                }}
              />
            </div>
          )}

          <Button
            label={mode === FormMode.CREATE ? "Create Admin" : "Save"}
            type="submit"
            className="form__button"
            disabled={isFormSubmitting || !isDirty}
          />
        </form>
      </StatusWrapper>
    </div>
  );
};
