import React, { FC } from "react";

import "./styles.scss";

export const BuildVersion: FC = () => {
  return (
    <div className="build-version">
    Build number: {process.env.REACT_APP_BUILD_NUMBER}
  </div>
  );
};