import React, { FC } from "react";
import cn from "classnames";

import "./styles.scss";

export enum ModalMode {
  DIALOG = "dialog",
  FORM = "form",
}

type ModalProps = {
  open: boolean;
  children: JSX.Element;
  onClose: () => void;
  mode?: ModalMode;
};

export const Modal: FC<ModalProps> = ({
  open,
  children,
  onClose,
  mode = ModalMode.FORM,
}) => {
  return open ? (
    <div className="modal__wrapper">
      <div
        className={cn([
          "modal",
          { "modal--dialog": mode === ModalMode.DIALOG },
        ])}
      >
        {mode === ModalMode.FORM && (
          <button onClick={onClose}>
            <img src="Close.png" />
          </button>
        )}
        {children}
      </div>
    </div>
  ) : null;
};
