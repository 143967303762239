import { Auth } from "aws-amplify";

export const getUserToken = async () =>
  (await Auth.currentSession()).getIdToken().getJwtToken();

export const INSTITUTIONS_URL = `https://${process.env.REACT_APP_BASE_URL}/web/institutions`;
export const INSTITUTION_ADMINS_URL = `https://${process.env.REACT_APP_BASE_URL}/web/users/institution-admins`;
export const GLOBAL_ADMINS_URL = `https://${process.env.REACT_APP_BASE_URL}/web/users/admins`;
export const HEALTHCARE_PROFESSIONALS_URL = `https://${process.env.REACT_APP_BASE_URL}/web/users/hcp`;
export const USER_URL = `https://${process.env.REACT_APP_BASE_URL}/web/users/me`;
