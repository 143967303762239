import { getUserToken, USER_URL } from "./utils";

export const getLoggedUserData = async () => {
  return await fetch(USER_URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${await getUserToken()}`,
    },
  });
};
