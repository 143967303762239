import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { LoadingStatus } from "../../components/StatusWrapper/constants";
import { InstitutionAdminElement } from "../../models/institutionAdmin";
import { getInstitutionAdminList } from "../../services/institutionAdmins";

export type InstitutionAdminListState = {
  content: InstitutionAdminElement[];
  status: LoadingStatus;
  pageNumber: number;
  pageCount: number;
};

const initialState: InstitutionAdminListState = {
  content: [],
  status: LoadingStatus.IDLE,
  pageNumber: 0,
  pageCount: 0,
};

export const fetchInstitutionAdminList = createAsyncThunk(
  "institutionAdminList/fetchInstitutionAdminList",
  async (pageIndex: number) => {
    const response = await getInstitutionAdminList(pageIndex);
    const data = response.json();
    return data;
  }
);

export const InstitutionAdminListSlice = createSlice({
  name: "institutionAdminList",
  initialState: initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInstitutionAdminList.pending, (state) => {
      return { ...state, status: LoadingStatus.LOADING };
    });
    builder.addCase(fetchInstitutionAdminList.fulfilled, (state, action) => {
      return {
        ...state,
        content: action.payload.content,
        status: LoadingStatus.SUCCESS,
        pageCount: action.payload.total_pages,
        pageNumber: action.payload.number,
      };
    });
  },
});

export default InstitutionAdminListSlice.reducer;
export const { clearData } = InstitutionAdminListSlice.actions;
