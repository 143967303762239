import React, { ButtonHTMLAttributes, FC } from "react";
import cn from "classnames";

import "./styles.scss";

export enum ButtonMode {
  CONFIRM = "confirm",
  DELETE = "delete",
}

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  label: string;
  mode?: ButtonMode;
};

export const Button: FC<ButtonProps> = ({
  label,
  className,
  mode = ButtonMode.CONFIRM,
  ...props
}) => {
  return (
    <button
      {...props}
      className={cn([
        "button",
        { "button--delete": mode === ButtonMode.DELETE },
        className,
      ])}
    >
      {label}
    </button>
  );
};
