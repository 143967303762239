import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { LoadingStatus } from "../../components/StatusWrapper/constants";
import { GlobalAdminElement } from "../../models/globalAdmin";
import { getGlobalAdminList } from "../../services/globalAdmins";

export type GlobalAdminListState = {
  content: GlobalAdminElement[];
  status: LoadingStatus;
  pageNumber: number;
  pageCount: number;
};

const initialState: GlobalAdminListState = {
  content: [],
  status: LoadingStatus.IDLE,
  pageNumber: 0,
  pageCount: 0,
};

export const fetchGlobalAdminList = createAsyncThunk(
  "globalAdminList/fetchGlobalAdminList",
  async (pageIndex: number) => {
    const response = await getGlobalAdminList(pageIndex);
    const data = response.json();
    return data;
  }
);

export const GlobalAdminListSlice = createSlice({
  name: "globalAdminList",
  initialState: initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGlobalAdminList.pending, (state) => {
      return { ...state, status: LoadingStatus.LOADING };
    });
    builder.addCase(fetchGlobalAdminList.fulfilled, (state, action) => {
      return {
        ...state,
        content: action.payload.content,
        status: LoadingStatus.SUCCESS,
        pageCount: action.payload.total_pages,
        pageNumber: action.payload.number,
      };
    });
  },
});

export default GlobalAdminListSlice.reducer;
export const { clearData } = GlobalAdminListSlice.actions;
