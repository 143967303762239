import React, { FC, useEffect, useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";

import { useAppDispatch } from "../../../../store";
import { fetchHcpList } from "../../../../store/features/hcpListSlice";
import { showToast, toastType } from "../../../../core/Toast/utils";
import {
  TITLE_OPTIONS,
  EMAIL_VALIDATOR,
  FormMode,
} from "../../../../utils/forms";
import {
  createHealthcareProfessional,
  editHealthcareProfessional,
} from "../../../../services/healthcareProfessionals";
import { HcpElement } from "../../../../models/hcp";
import { Button } from "../../../../components/Button";
import { Input } from "../../../../components/Input";
import { Select } from "../../../../components/Select";
import "../../../../styles/form.scss";

type HealthcareProfessionalFormProps = {
  onClose: () => void;
  mode: FormMode;
  initialValues?: HcpElement;
};

export const HealthcareProfessionalForm: FC<
  HealthcareProfessionalFormProps
> = ({ onClose, initialValues, mode }) => {
  const dispatch = useAppDispatch();
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);

  const defaultValues = useMemo(() => {
    return {
      title: initialValues?.title || "",
      first_name: initialValues?.first_name || "",
      last_name: initialValues?.last_name || "",
      email: initialValues?.email || "",
      email_confirm: "",
    } as FieldValues;
  }, [initialValues]);

  const {
    register,
    handleSubmit,
    getValues,
    trigger,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onTouched",
    defaultValues: defaultValues,
  });

  const onSubmit = async (data: FieldValues) => {
    setIsFormSubmitting(true);
    const formData = {
      title: data.title,
      first_name: data.first_name,
      last_name: data.last_name,
      email: mode === FormMode.CREATE ? data.email : undefined,
    };

    (mode === FormMode.CREATE
      ? createHealthcareProfessional(formData)
      : editHealthcareProfessional(formData, initialValues?.id)
    ).then((response) => {
      setIsFormSubmitting(false);
      if (![200, 201].includes(response.status)) {
        if (response.status === 409) {
          showToast(
            "An account with the given email already exists.",
            toastType.ERROR
          );
        } else {
          showToast("An error occurred.", toastType.ERROR);
        }
      } else {
        showToast(
          mode === FormMode.CREATE ? "Account created." : "Changes saved.",
          toastType.SUCCESS
        );
        onClose();
        dispatch(fetchHcpList(0));
      }
    });
  };

  const email = watch("email");
  const email_confirm = watch("email_confirm");

  useEffect(() => {
    if (email_confirm) {
      trigger("email_confirm");
    }
  }, [email, email_confirm, trigger]);

  return (
    <div className="form">
      <div className="form__header">
        <img src="Create.png" />
        {mode === FormMode.CREATE
          ? "Create Healthcare Professional account"
          : "Edit Healthcare Professional account"}
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form__row--short">
          <Select
            options={TITLE_OPTIONS}
            name="title"
            label="Title"
            errors={errors}
            register={register}
            className="form__title-select"
          />
        </div>

        <div className="form__row">
          <Input
            type="text"
            name="first_name"
            label="First Name"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Required",
              maxLength: {
                value: 100,
                message: "Max 100 characters",
              },
            }}
          />

          <Input
            type="text"
            name="last_name"
            label="Last Name"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Required",
              maxLength: {
                value: 100,
                message: "Max 100 characters",
              },
            }}
          />
        </div>

        <div className="form__row">
          <Input
            type="text"
            name="email"
            label="E-mail address"
            errors={errors}
            disabled={mode === FormMode.EDIT}
            register={register}
            validationSchema={{
              required: "Required",
              maxLength: {
                value: 100,
                message: "Max 100 characters",
              },
              pattern: {
                value: EMAIL_VALIDATOR,
                message: "Invalid email address",
              },
            }}
          />
        </div>

        {mode === FormMode.CREATE && (
          <div className="form__row">
            <Input
              type="text"
              name="email_confirm"
              label="Confirm e-mail address"
              errors={errors}
              register={register}
              validationSchema={{
                validate: (value: string) =>
                  value === getValues("email") || "Values are not the same",
                required: "Required",
              }}
            />
          </div>
        )}

        <Button
          label={mode === FormMode.CREATE ? "Create HCP account" : "Save"}
          type="submit"
          className="form__button"
          disabled={isFormSubmitting || !isDirty}
        />
      </form>
    </div>
  );
};
