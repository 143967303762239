import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LoggedUser } from "../../models/loggedUser";
import { getLoggedUserData } from "../../services/user";
import { LoadingStatus } from "../../components/StatusWrapper/constants";

export interface UserState extends LoggedUser {
  role: string;
  status: LoadingStatus;
}

const initialState: UserState = {
  id: "",
  first_name: "",
  last_name: "",
  email: "",
  title: "",
  role_display: "",
  institution_name: "",
  role: "",
  status: LoadingStatus.IDLE,
};

export const fetchLoggedUserData = createAsyncThunk(
  "user/fetchLoggedUserData",
  async () => {
    const response = await getLoggedUserData();
    const data = response.json();
    return data;
  }
);

export const UserSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<{ role: string }>) => {
      return (state = {
        ...state,
        role: action.payload.role,
      });
    },
    clearUserData: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLoggedUserData.pending, (state) => {
      return { ...state, status: LoadingStatus.LOADING };
    });
    builder.addCase(fetchLoggedUserData.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.SUCCESS,
        ...action.payload,
      };
    });
  },
});

export default UserSlice.reducer;
export const { setUserData, clearUserData } = UserSlice.actions;
