import { FieldValues } from "react-hook-form";

import { getUserToken, INSTITUTIONS_URL } from "./utils";

export const createInstitution = async (
  data: FieldValues
): Promise<Response> => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getUserToken()}`,
    },
    body: JSON.stringify(data),
  };
  return fetch(INSTITUTIONS_URL, requestOptions);
};

export const editInstitution = async (
  data: FieldValues,
  id: number | undefined
): Promise<Response> => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getUserToken()}`,
    },
    body: JSON.stringify(data),
  };
  return fetch(`${INSTITUTIONS_URL}/${id}`, requestOptions);
};

export const deleteInstitution = async (id: number): Promise<Response> => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${await getUserToken()}`,
    },
  };
  return fetch(`${INSTITUTIONS_URL}/${id}`, requestOptions);
};

export const getInstitutionList = async (params: {
  pageIndex: number;
  pageSize?: number;
}) => {
  return await fetch(
    `${INSTITUTIONS_URL}?page=${params.pageIndex}&size=${
      params.pageSize || 10
    }`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${await getUserToken()}`,
      },
    }
  );
};
