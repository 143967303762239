import React from "react";
import { UseTableInstanceProps } from "react-table";

import "./styles.scss";

export interface TableNewProps<T extends object>
  extends Pick<
    UseTableInstanceProps<T>,
    | "getTableProps"
    | "headerGroups"
    | "getTableBodyProps"
    | "prepareRow"
    | "rows"
  > {
  pageNumber: number;
  pageCount: number;
  goToPage: (page: number) => void;
}

export function Table<T extends object>(props: TableNewProps<T>) {
  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    pageCount,
    pageNumber,
    goToPage,
  } = props;

  return (
    <div>
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.getHeaderGroupProps().key}
            >
              {headerGroup.headers.map((column) => (
                <th
                  className="table__head"
                  {...column.getHeaderProps()}
                  key={column.getHeaderProps().key}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                key={row.getRowProps().key}
                className="table__row"
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      key={cell.getCellProps().key}
                      className="table__data"
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      {pageCount > 1 && (
        <div className="paginator">
          <button
            className="paginator__button"
            disabled={pageNumber === 0}
            onClick={() => goToPage(pageNumber - 1)}
            id="paginator-button-prev"
          >
            <img src="ChevronLeft.svg" />
          </button>
          Page {pageNumber + 1} of {pageCount}
          <button
            className="paginator__button"
            disabled={pageNumber === pageCount - 1}
            onClick={() => goToPage(pageNumber + 1)}
            id="paginator-button-next"
          >
            <img src="ChevronRight.svg" />
          </button>
        </div>
      )}
    </div>
  );
}
