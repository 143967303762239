import React, { FC, useCallback, useEffect, useState } from "react";
import { useTable } from "react-table";

import { FormMode } from "../../utils/forms";
import { InstitutionElement } from "../../models/institutions";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  fetchInstitutionList,
  clearData,
} from "../../store/features/institutionListSlice";
import { Table } from "../../components/Table";
import { StatusWrapper } from "../../components/StatusWrapper/StatusWrapper";
import { Modal } from "../../components/Modal";
import { Button } from "../../components/Button";
import { InstitutionActions } from "./components/InstitutionActions";
import { InstitutionForm } from "./components/InstitutionForm";
import { columns, InstitutionListData } from "./constants";
import "./styles.scss";

const prepareData = (fetchedData: InstitutionElement[]): InstitutionListData[] => {
  const tableData: InstitutionListData[] = [];
  
  fetchedData?.map((institution) => {
    tableData.push({
      name: institution.name,
      street: institution.street,
      postCode: institution.post_code,
      city: institution.city,
      actions: <InstitutionActions institution={institution}/>,
    });
  });

  return tableData;
};

export const Institutions: FC = () => {
  const dispatch = useAppDispatch();
  const institutionList = useAppSelector((state) => state.institutionList);
  const [isCreateModalOpen, setCreateModalOpen] = useState<boolean>(false);

  const toggleCreateModal = useCallback(
    () => setCreateModalOpen((state) => !state),
    []
  );

  const data = React.useMemo<InstitutionListData[]>(
    () => prepareData(institutionList.content),
    [institutionList]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<InstitutionListData>({ columns, data });

  useEffect(() => {
    dispatch(fetchInstitutionList({ pageIndex: 0 }));

    return () => {
      dispatch(clearData());
    };
  }, []);

  const handlePageChange = useCallback((pageNumber: number) => {
    dispatch(fetchInstitutionList({ pageIndex: pageNumber }));
  }, []);

  return (
    <div className="institution-list">
      <Modal open={isCreateModalOpen} onClose={toggleCreateModal}>
        <InstitutionForm onClose={toggleCreateModal} mode={FormMode.CREATE}/>
      </Modal>

      <StatusWrapper status={institutionList.status}>
        <Table<InstitutionListData>
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
          pageCount={institutionList.pageCount}
          pageNumber={institutionList.pageNumber}
          goToPage={handlePageChange}
        />
      </StatusWrapper>

      <Button label="Create" onClick={toggleCreateModal} />
    </div>
  );
};
