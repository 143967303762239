import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { LoadingStatus } from "../../components/StatusWrapper/constants";
import { getHealthcareProfessionalList } from "../../services/healthcareProfessionals";
import { HcpElement } from "../../models/hcp";

export type HcpListState = {
  content: HcpElement[];
  status: LoadingStatus;
  pageNumber: number;
  pageCount: number;
};

const initialState: HcpListState = {
  content: [],
  status: LoadingStatus.IDLE,
  pageNumber: 0,
  pageCount: 0,
};

export const fetchHcpList = createAsyncThunk(
  "hcpList/fetchHcpList",
  async (pageIndex: number) => {
    const response = await getHealthcareProfessionalList(pageIndex);
    const data = response.json();
    return data;
  }
);

export const HcpListSlice = createSlice({
  name: "hcpList",
  initialState: initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHcpList.pending, (state) => {
      return { ...state, status: LoadingStatus.LOADING };
    });
    builder.addCase(fetchHcpList.fulfilled, (state, action) => {
      return {
        ...state,
        content: action.payload.content,
        status: LoadingStatus.SUCCESS,
        pageCount: action.payload.total_pages,
        pageNumber: action.payload.number,
      };
    });
  },
});

export default HcpListSlice.reducer;
export const { clearData } = HcpListSlice.actions;
