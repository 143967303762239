import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";

import { UserSlice } from "./features/userSlice";
import { InstitutionListSlice } from "./features/institutionListSlice";
import { InstitutionAdminListSlice } from "./features/institutionAdminListSlice";
import { HcpListSlice } from "./features/hcpListSlice";
import { GlobalAdminListSlice } from "./features/globalAdminListSlice";

export const store = configureStore({
  reducer: {
    user: UserSlice.reducer,
    institutionList: InstitutionListSlice.reducer,
    institutionAdminList: InstitutionAdminListSlice.reducer,
    globalAdminList: GlobalAdminListSlice.reducer,
    hcpList: HcpListSlice.reducer
  },
});

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<
  ReturnType<typeof store.getState>
> = useSelector;
