import React, { FC } from "react";
import { Authenticator as AmplifyAuthenticator } from "@aws-amplify/ui-react";

import { PageLayout } from "../PageLayout";
import "./styles.scss";

const components = {
  Header() {
    return (
      <div className="authenticator__header">
        <img src="Logo.png" className="authenticator__logo" />
        <div>Welcome to AffeX +</div>
      </div>
    );
  },
};

export const Authenticator: FC = () => {
  return (
    <AmplifyAuthenticator components={components} hideSignUp={true}>
      {({ signOut, user }) => (
        <PageLayout user={user} signOut={signOut}/>
      )}
    </AmplifyAuthenticator>
  );
};
