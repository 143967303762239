import { FieldValues } from "react-hook-form";

import { getUserToken, HEALTHCARE_PROFESSIONALS_URL } from "./utils";

export const createHealthcareProfessional = async (
  data: FieldValues
): Promise<Response> => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getUserToken()}`,
    },
    body: JSON.stringify(data),
  };
  return fetch(HEALTHCARE_PROFESSIONALS_URL, requestOptions);
};

export const editHealthcareProfessional = async (
  data: FieldValues,
  id: string | undefined
): Promise<Response> => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getUserToken()}`,
    },
    body: JSON.stringify(data),
  };
  return fetch(`${HEALTHCARE_PROFESSIONALS_URL}/${id}`, requestOptions);
};

export const deleteHealthcareProfessional = async (
  id: string
): Promise<Response> => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${await getUserToken()}`,
    },
  };
  return fetch(`${HEALTHCARE_PROFESSIONALS_URL}/${id}`, requestOptions);
};

export const getHealthcareProfessionalList = async (pageIndex: number) => {
  return await fetch(
    `${HEALTHCARE_PROFESSIONALS_URL}?page=${pageIndex}&size=10`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${await getUserToken()}`,
      },
    }
  );
};
