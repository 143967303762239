import React, { FC, useCallback, useState } from "react";

import { showToast, toastType } from "../../../../core/Toast/utils";
import { deleteInstitution } from "../../../../services/institutions";
import { fetchInstitutionList } from "../../../../store/features/institutionListSlice";
import { useAppDispatch } from "../../../../store";
import { FormMode } from "../../../../utils/forms";
import { InstitutionElement } from "../../../../models/institutions";
import { DeleteDialogContent } from "../../../../components/DeleteDialogContent";
import { Modal, ModalMode } from "../../../../components/Modal";
import { TableActionButtonWrapper } from "../../../../components/Table/components/TableActionButtonWrapper";
import { InstitutionForm } from "../InstitutionForm";

type InstitutionActionsProps = {
  institution: InstitutionElement;
};

export const InstitutionActions: FC<InstitutionActionsProps> = ({
  institution,
}) => {
  const dispatch = useAppDispatch();

  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState<boolean>(false);

  const toggleDeleteModal = useCallback(
    () => setDeleteModalOpen((state) => !state),
    []
  );

  const toggleEditModal = useCallback(
    () => setEditModalOpen((state) => !state),
    []
  );

  const onDelete = async () => {
    setIsDeleteInProgress(true);
    deleteInstitution(institution.id).then((response) => {
      setIsDeleteInProgress(false);
      if (response.status !== 200) {
        if (response.status === 409) {
          showToast(
            "All administrators assigned to this institution must be removed first.",
            toastType.ERROR
          );
          toggleDeleteModal();
        } else {
          showToast("An error occurred.", toastType.ERROR);
        }
      } else {
        showToast("Institution deleted.", toastType.SUCCESS);
        toggleDeleteModal();
        dispatch(fetchInstitutionList({ pageIndex: 0 }));
      }
    });
  };

  return (
    <div>
      <Modal
        open={isDeleteModalOpen}
        onClose={toggleDeleteModal}
        mode={ModalMode.DIALOG}
      >
        <DeleteDialogContent
          message={`Delete institution '${institution.name}'?`}
          onDelete={onDelete}
          onCancel={toggleDeleteModal}
          deleteDisabled={isDeleteInProgress}
        />
      </Modal>

      <Modal
        open={isEditModalOpen}
        onClose={toggleEditModal}
        mode={ModalMode.FORM}
      >
        <InstitutionForm
          onClose={toggleEditModal}
          mode={FormMode.EDIT}
          initialValues={institution}
        />
      </Modal>

      <TableActionButtonWrapper
        onDelete={toggleDeleteModal}
        onEdit={toggleEditModal}
      />
    </div>
  );
};
